import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            theme: 'hisstory-theme',
            'disable-default-analytics': true,
            'accordion-month-header': { arrowSvgName: 'icon-arrow' },
            'selected-event': {
                buttonClass: 'button button--secondary',
                eventFormat(event) {
                    return `${event.format(
                        'ddd, MMM DD',
                    )} at <span class="tc-selected-event__time">${event.format(
                        event.smartTimeFormat(''),
                    )}</span><span class="tc-selected-event__meridiem">${event.format('A')}</span>`;
                },
            },
        });
    });
});
